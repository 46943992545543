<template>
  <v-card>
    <v-card-title class="justify-center mb-2">
      Cadastrar
    </v-card-title>

    <v-card-text>
      <v-text-field
        v-model="identifyCode"
        label="Código de identificação"
        outlined
        dense
      />

      <v-text-field
        v-model="description"
        label="Descrição do documento"
        outlined
        dense
      />

      <v-autocomplete
        v-model="processIdSelected"
        :items="processList"
        item-text="description"
        item-value="id"
        label="Processo"
        outlined
        dense
      />

      <v-autocomplete
        v-model="documentTypeIdSelected"
        :items="documentTypeList"
        item-text="name"
        item-value="id"
        label="Tipo de documento"
        outlined
        dense
      />

      <v-autocomplete
        v-model="occupationIdSelected"
        :items="occupationList"
        item-text="occupation.name"
        item-value="occupations_id"
        label="Funções"
        outlined
        multiple
        clearable
        chips
        dense
      />

      <div class="d-flex justify-end mt-2">
        <v-btn
          color="info"
          @click="sendModalData()"
        >
          <span
            v-if="!isLoading"
          >Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>

        <v-btn
          color="error"
          class="ml-5"
          @click="closeModal()"
        >
          Cancelar
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'

export default {
  mixins: [formatters, messages],

  props: {
    documentTypeList: {
      type: [Array, Object],
      required: true,
    },

    processList: {
      type: [Array, Object],
      required: true,
    },

    occupationList: {
      type: [Object, Array],
      required: true,
    },
  },

  data() {
    return {
      description: '',
      identifyCode: '',
      processIdSelected: '',
      documentTypeIdSelected: '',

      occupationIdSelected: [],
      isLoading: false,
    }
  },

  methods: {
    async sendModalData() {
      this.isLoading = true

      const {
        identifyCode, description, processIdSelected, documentTypeIdSelected, occupationIdSelected,
      } = this

      const body = {
        identify: identifyCode,
        description: description.toUpperCase(),
        process_id: processIdSelected,
        types_id: documentTypeIdSelected,
        occupations_id: occupationIdSelected,
      }

      await axiosIns.post('/api/v1/sgq/document/store', body)
        .then(() => {
          this.showMessage({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Sua resposta enviada com sucesso!',
          })

          this.updatedTable()
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            text: error,
          })
        })
        .finally(() => {
          this.isLoading = false
          this.clearData()
          this.closeModal()
        })
    },

    clearData() {
      this.description = ''
      this.identifyCode = ''
      this.processIdSelected = ''
      this.documentTypeIdSelected = ''
    },
  },
}
</script>
